import { request } from '@/util/vueRequest.js'

/**
 * @description: 获取原片下料刀选择列表
 * @return {*}
 */
export function getBlankTableList() {
  return request({
    url: '/plate_knife_maps',
    method: 'GET',
  })
}
export function createBlankTableList(data) {
  return request({
    url: '/plate_knife_maps',
    method: 'POST',
    data,
  })
}
export function editBlankTableList(id, data) {
  return request({
    url: `/plate_knife_maps/${id}`,
    method: 'PUT',
    data,
  })
}
export function deleteBlankTableList(id) {
  return request({
    url: `/plate_knife_maps/${id}`,
    method: 'DELETE',
  })
}

// 原片管理：修改原片数据
export function updateBasePlank(params) {
  return request.post('save_plank_manage_setting_info', params)
}

// 原片管理： 加载原片数据
export function getBasePlank(params) {
  return request.post('load_plank_manage_setting_info', params)
}
