<template>
  <el-drawer
    :visible.sync="visible"
    direction="rtl"
    :size="500"
    :show-close="false"
    :close-on-press-escape="false"
    @close="onCloseDrawer"
  >
    <div slot="title" class="drawer-title">
      <span>{{ title }}</span>
      <span class="iconfont icon-close" @click="onCloseDrawer"></span>
    </div>
    <div class="drawer-main">
      <div class="new-plank-form h100">
        <div class="new-plank-form-item">
          <span class="plank-form-title">
            <span class="important-icon">*</span>
            <!-- 板材宽度 -->
            {{ $t('common.plankWidth') }}：
          </span>
          <div class="plank-form-input">
            <a-input
              v-model="currentDrawData.plankWidth"
              @input="handleInputCheck('plankWidth')"
              @blur="handleInputCheck('plankWidth')"
              :class="curInputAlert.plankWidth ? 'alert-input' : ''"
            >
              <span slot="addonAfter" class="plank-form-after">mm</span>
            </a-input>
            <transition name="errorInfo">
              <span v-show="curInputAlert.plankWidth" class="error-info">{{
                curInputAlert.plankWidth
              }}</span>
            </transition>
          </div>
        </div>
        <div class="new-plank-form-item">
          <span class="plank-form-title">
            <span class="important-icon">*</span>
            <!-- 板材长度 -->
            {{ $t('common.plankHeight') }}：
          </span>
          <div class="plank-form-input">
            <a-input
              v-model="currentDrawData.plankHeight"
              @input="handleInputCheck('plankHeight')"
              @blur="handleInputCheck('plankHeight')"
              :class="curInputAlert.plankHeight ? 'alert-input' : ''"
            >
              <span slot="addonAfter" class="plank-form-after">mm</span>
            </a-input>
            <transition name="errorInfo">
              <span v-show="curInputAlert.plankHeight" class="error-info">{{
                curInputAlert.plankHeight
              }}</span>
            </transition>
          </div>
        </div>
        <div class="new-plank-form-item">
          <span class="plank-form-title">
            <span class="important-icon">*</span>
            <!-- 修边尺寸 -->
            {{ $t('basePlankManager.offsetSize') }}：
          </span>
          <div class="plank-form-input">
            <a-input
              v-model="currentDrawData.plankEdgeOff"
              @input="handleInputCheck('plankEdgeOff')"
              @blur="handleInputCheck('plankEdgeOff')"
              :class="curInputAlert.plankEdgeOff ? 'alert-input' : ''"
            >
              <span slot="addonAfter" class="plank-form-after">mm</span>
            </a-input>
            <transition name="errorInfo">
              <span v-show="curInputAlert.plankEdgeOff" class="error-info">{{
                curInputAlert.plankEdgeOff
              }}</span>
            </transition>
          </div>
        </div>
      </div>
      <div class="drawer-bottom">
        <span class="drawer-bottom-btn" @click="onCloseDrawer">
          <!-- 取消 -->
          {{ $t('common.cancel') }}
        </span>
        <span
          class="drawer-bottom-btn"
          v-if="isCreateNewSlab"
          @click="onSave($event, true)"
        >
          <!-- 保存并继续 -->
          <g-t-view :text="$t('common.saveAndContinue')" width="100%" />
        </span>
        <span class="drawer-bottom-btn confirm-btn" @click="onSave($event)">
          <!-- 保存 -->
          {{ $t('common.save') }}
        </span>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import GTView from '@/components/translateComp/g-t-view.vue'
import { regReplaceBaseMaterialDefaultInputCheck } from '@/util/regReplace'

export default {
  components: { GTView },
  props: {
    showVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '新建标准板材',
    },
    drawerData: {},
    copyDrawerData: {},
    standardData: {
      type: Array,
      default: () => [],
    },
    standardRowIndex: {
      type: Number,
      default: null,
    },
    inputAlert: {
      type: Object,
      default: () => ({
        plankWidth: '',
        plankHeight: '',
        plankEdgeOff: '',
      }),
    },
    isCreateNewSlab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 记录各种输入警示
      curInputAlert: this.inputAlert,
      inputAlertMap: {
        plankWidth: '板材宽度',
        plankHeight: '板材长度',
        plankEdgeOff: '修边尺寸',
      },
      visible: this.showVisible,
      currentDrawData: this.drawerData,
    }
  },
  watch: {
    showVisible: {
      handler(n) {
        this.visible = n
      },
      deep: true,
    },
    drawerData: {
      handler(n) {
        this.currentDrawData = n
      },
      deep: true,
    },
    inputAlert: {
      handler(n) {
        this.curInputAlert = n
      },
      deep: true,
    },
  },
  methods: {
    onCloseDrawer() {
      this.$emit('onCloseDrawer')
    },
    // 输入框输入事件
    handleInputCheck(type) {
      if (this.dealDecimal(type)) {
        this.curInputAlert[type] = `${this.inputAlertMap[type]}只能输入数字!`
      } else if (
        this.currentDrawData[type] === '' ||
        (type != 'plankEdgeOff' && Number(this.currentDrawData[type]) == 0)
      ) {
        this.curInputAlert[type] = `${this.inputAlertMap[type]}不能为空!`
      } else {
        this.curInputAlert[type] = ''
      }
      this.currentDrawData[type] = regReplaceBaseMaterialDefaultInputCheck(
        this.currentDrawData[type]
      )
      this.currentDrawData[type] =
        this.currentDrawData[type].indexOf('.') > 0
          ? this.currentDrawData[type].split('.')[0].substring(0, 5) +
            '.' +
            this.currentDrawData[type].split('.')[1]
          : this.currentDrawData[type].substring(0, 5) //整数部分保留五位数
    },
    dealDecimal(type) {
      let reg = /[^\d.]/
      let flag = false
      if (
        this.currentDrawData[type] !== '空' &&
        reg.test(this.currentDrawData[type])
      ) {
        flag = true
      }
      this.currentDrawData[type] = regReplaceBaseMaterialDefaultInputCheck(
        String(this.currentDrawData[type]),
        true
      )
      return flag
    },
    // 判断输入是否为空
    checkInputIsBlank() {
      let ableSave = true
      Object.keys(this.currentDrawData).forEach((key) => {
        if (
          Object.keys(this.inputAlertMap).includes(key) &&
          (this.currentDrawData[key] === '' ||
            (key != 'plankEdgeOff' && Number(this.currentDrawData[key]) == 0))
        ) {
          this.curInputAlert[key] = `${this.inputAlertMap[key]}不能为空!`
          ableSave = false
        }
      })
      return ableSave
    },
    // 判断是否重复
    cancelIsRepeat(plank) {
      let repeat = false
      for (let i = 0; i < this.standardData.length; i++) {
        let item = this.standardData[i]
        if (
          item.plankWidth == plank.plankWidth &&
          item.plankHeight == plank.plankHeight &&
          item.plankEdgeOff == plank.plankEdgeOff
        ) {
          repeat = true
          break
        }
      }
      return repeat
    },
    onSave(event, isContinue) {
      // 判断是否可以保存
      let ableSave = this.checkInputIsBlank()
      if (!ableSave) return
      // 判断是否修改
      let isEdit =
        this.copyDrawerData.plankHeight == this.currentDrawData.plankHeight &&
        this.copyDrawerData.plankWidth == this.currentDrawData.plankWidth &&
        this.copyDrawerData.plankEdgeOff == this.currentDrawData.plankEdgeOff &&
        this.title.includes('编辑')
      if (this.cancelIsRepeat(this.currentDrawData) && !isEdit) {
        this.$message.error('板件已存在, 请核对!')
        return
      }
      this.$emit('onSave', this.currentDrawData, {
        isContinue,
        rowIndex: this.standardRowIndex,
        isEdit,
      })
    },
  },
}
</script>

<style scoped lang="less">
.drawer-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 24px;
  color: #000;
  font-weight: 500;
  font-size: 18px;
  font-family: PingFangSC-Medium, 'PingFang SC';
  border-color: rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid;
}

.new-plank-form {
  box-sizing: border-box;
  padding: 24px;

  .new-plank-form-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    /deep/.el-input {
      width: 368px;

      .el-input__inner {
        height: 32px;
      }
    }

    .error-info {
      position: absolute;
      top: 32px;
      left: 84px;
      color: #fd3232;
      font-size: 12px;
    }

    .plank-form-title {
      display: inline-block;
      flex-shrink: 0;
      width: 88px;
      color: #666;
      font-size: 14px;
      text-align: right;
      cursor: default;
    }

    .plank-form-input {
      width: 368px;

      /deep/ .absolute {
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: 9999;
      }

      .plank-form-select.ant-select-dropdown {
        z-index: 3000;
      }

      /deep/ .el-select {
        display: block;

        .is-focus {
          .el-input__inner {
            border-color: #18a8c7;

            &:focus {
              border-color: #18a8c7;
            }
          }
        }

        .el-input__inner {
          &:focus {
            border-color: #18a8c7;
          }
        }
      }

      /deep/.el-cascader {
        display: block;

        .el-input {
          .el-input__inner:focus,
          .el-cascader .el-input.is-focus .el-input__inner {
            border-color: #18a8c7;
          }
        }
      }
    }

    .colorInpWrap {
      .error-info {
        position: absolute;
        top: 32px;
        left: 0;
        color: #fd3232;
        font-size: 12px;
      }
    }

    .plank-form-limit {
      color: #b0b0b0;
      font-size: 14px;
      cursor: default;
      user-select: none;
    }

    .plank-form-after {
      color: #b0b0b0;
      font-size: 14px;
      cursor: default;
      user-select: none;
    }

    .important-icon {
      margin-right: 4px;
      color: red;
      font-size: 14px;
    }

    .ant-select {
      width: 368px;
    }
  }
}

.drawer-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding: 0 56px 0 68px;
  background: rgba(216, 216, 216, 0);
  border-color: rgba(0, 0, 0, 0.06);
  border-top: 1px solid;
  box-shadow: 0 1px 0 0 rgb(229, 229, 229);

  span {
    display: inline-block;
    width: 120px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }

  .drawer-bottom-btn {
    box-sizing: border-box;
    color: #333;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 2px;
    cursor: pointer;
  }

  .confirm-btn {
    color: #fff;
    background: #18a8c7;
    border: none;
  }
}
</style>
