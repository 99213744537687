import axios, { AxiosResponse } from 'axios'
import { AxiosRequestConfig } from 'axios'

/**
 * @description 调用接口请求数据，需要保证 http 和 https 都调用一次使用成功的结果
 * @param host 主机+路径 or 主机+路径+参数
 * @param preload 额外携带的参数
 */
export function requestWithDualProtocol(
  host: string,
  options: AxiosRequestConfig = { method: 'get' as const },
  preload?: any
) {
  return new Promise<AxiosResponse<any, any>>(async (resolve, reject) => {
    try {
      const request = ['http://', 'https://'].map((protocol) => {
        return axios({ ...options, url: protocol + host, data: preload })
      })
      const result = await Promise.allSettled(request)
      const successResult = result.find(
        (item) => item.status === 'fulfilled'
      ) as any
      if (!successResult?.value) {
        reject({ msg: '请求失败', error: result })
        return
      }
      resolve(successResult.value as any)
    } catch (error) {
      reject({ msg: '请求异常', error })
    }
  })
}
