// 限制刀径的输入
export function regReplaceCuttingParamsDiameter(data: any) {
  data = data
    .replace(/^[^\d]/) // 第一个字符只能输入数字
    .replace(/[^\d.|\-]/g, '') // 去除数字和小数点以外的内容
    .replace(/\-{1,}/g, '') // 去除负号
    .replace(/\.{2,}/g, '.') // 去除多余小数点
    .replace('.', '$#$') // 将小数点暂存为其他字符
    .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
    .replace('$#$', '.') // 将暂存的小数点替换回来
  if (data.indexOf('.') < 0 && data != '') {
    //此处控制的是如果没有小数点，首位不能为类似于01,02
    data = parseFloat(data)
  }
  return data
}
// 侧孔机替换
export function regReplaceSideHole(data: any, columnIndex: number) {
  if (columnIndex === 1) {
    return data
      .replace(/^[^\d]/) // 第一个字符只能输入负号或数字
      .replace(/[^\d.|\-]/g, '') // 去除数字和小数点以外的数字
      .replace(/\-{1,}/g, '') // 去除多余的负号
      .replace(/\-/, '#$#') // 将负号暂存为其他字符
      .replace(/\-/g, '') // 替换多余的负号, 防止输入时的闪烁
      .replace('#$#', '-') // 替换完成之后将暂存负号替换回来
      .replace(/\.{2,}/g, '.') // 去除多余小数点
      .replace('.', '$#$') // 将小数点暂存为其他字符
      .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
      .replace('$#$', '.') // 将暂存的小数点替换回来
  } else if (columnIndex == 2 || columnIndex == 3 || columnIndex == 4) {
    return data
      .replace(/^[^\-|^\d]/) // 第一个字符只能输入负号或数字
      .replace(/[^\d.|\-]/g, '') // 去除数字和小数点以外的数字
      .replace(/\|{1,}/g, '') //去除字符中的竖线|
      .replace(/\-{2,}/g, '') // 去除多余的负号
      .replace(/\-/, '#$#') // 将负号暂存为其他字符
      .replace(/\-/g, '') // 替换多余的负号, 防止输入时的闪烁
      .replace('#$#', '-') // 替换完成之后将暂存负号替换回来
      .replace(/\.{2,}/g, '.') // 去除多余小数点
      .replace('.', '$#$') // 将小数点暂存为其他字符
      .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
      .replace('$#$', '.') // 将暂存的小数点替换回来
      .replace(/^(\-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3') //保留三位小数
  }
}

// 雕刻机-切割参数
export function regReplaceCuttingParamsInputCheck(data: any) {
  return data
    .replace(/^[^\-|^\d]/) // 第一个字符只能输入负号或数字
    .replace(/[^\d.|\-]/g, '') // 去除数字和小数点以外的数字
    .replace(/\|{1,}/g, '') // 去除多余的负号
    .replace(/\-{2,}/g, '') // 去除多余的负号
    .replace(/\-/, '#$#') // 将负号暂存为其他字符
    .replace(/\-/g, '') // 替换多余的负号, 防止输入时的闪烁
    .replace('#$#', '-') // 替换完成之后将暂存负号替换回来
    .replace(/\.{2,}/g, '.') // 去除多余小数点
    .replace('.', '$#$') // 将小数点暂存为其他字符
    .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
    .replace('$#$', '.') // 将暂存的小数点替换回来
}
// 雕刻机-切割参数
export function regReplaceCuttingParamsInputDepth(data: any) {
  return data
    .replace(/^[^\d]/) // 第一个字符只能输入数字
    .replace(/[^\d.|\-]/g, '') // 去除数字和小数点以外的数字
    .replace(/\-{1,}/g, '') // 去除负号
    .replace(/\.{2,}/g, '.') // 去除多余小数点
    .replace('.', '$#$') // 将小数点暂存为其他字符
    .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
    .replace('$#$', '.') // 将暂存的小数点替换回来
    .replace(/^(\-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3')
}
// 雕刻机-切割参数
export function regReplaceCuttingParamsHandleInputVal(data: any, Reg: any) {
  return data
    .replace(/\.+?/, '(')
    .replace(/\./g, '')
    .replace(/\(/, '.')
    .replace(Reg, '$1$2$3')
    .replace(/[^\d\.\-]/, '')
}

export function regReplaceCuttingDockInputCheck(data: any) {
  return data
    .replace(/^[^\-|^\d]/) // 第一个字符只能输入负号或数字
    .replace(/[^\d.|\-]/g, '') // 去除数字和小数点以外的数字
    .replace(/\-{2,}/g, '') // 去除多余的负号
    .replace(/\-/, '#$#') // 将负号暂存为其他字符
    .replace(/\-/g, '') // 替换多余的负号, 防止输入时的闪烁
    .replace('#$#', '-') // 替换完成之后将暂存负号替换回来
    .replace(/\.{2,}/g, '.') // 去除多余小数点
    .replace('.', '$#$') // 将小数点暂存为其他字符
    .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
    .replace('$#$', '.') // 将暂存的小数点替换回来
}

export function regReplaceBluenPtpDealNumber(data: any) {
  return data
    .replace(/^[^\-|^\d]/) // 第一个字符只能输入负号或数字
    .replace(/[^\d.|\-]/g, '') // 去除数字和小数点以外的数字
    .replace(/\-{2,}/g, '') // 去除多余的负号
    .replace(/\-/, '#$#') // 将负号暂存为其他字符
    .replace(/\-/g, '') // 替换多余的负号, 防止输入时的闪烁
    .replace('#$#', '-') // 替换完成之后将暂存负号替换回来
    .replace(/\.{2,}/g, '.') // 去除多余小数点
    .replace('.', '$#$') // 将小数点暂存为其他字符
    .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
    .replace('$#$', '.') // 将暂存的小数点替换回来
    .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只保留两位小数
}

export function regReplaceSupplusStorageDealDecimal(data: any, decimal: any) {
  if (decimal) {
    /* eslint-disable */
    return data
      .replace(/^[^\d]/) // 第一个字符只能输入负号或数字
      .replace(/[^\d.]/g, '') // 去除数字和小数点以外的数字
      .replace(/\.{2,}/g, '.') // 去除多余小数点
      .replace('.', '$#$') // 将小数点暂存为其他字符
      .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
      .replace('$#$', '.') // 将暂存的小数点替换回来
      .replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3') // 只保留两位小数
  } else {
    return data
      .replace(/^[^\d]/) // 第一个字符只能输入负号或数字
      .replace(/[^\d.]/g, '') // 去除数字和小数点以外的数字
      .replace(/\.{2,}/g, '.') // 去除多余小数点
      .replace('.', '$#$') // 将小数点暂存为其他字符
      .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
      .replace('$#$', '.') // 将暂存的小数点替换回来
      .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只保留两位小数
  }
}

export function regReplaceBaseMaterialDefaultInputCheck(
  data: any,
  type: string
) {
  if (type) {
    return data
      .replace(/^[^\d]/) // 第一个字符只能输入负号或数字
      .replace(/[^\d.|\-]/g, '') // 去除数字和小数点以外的数字
      .replace(/\-{1,}/g, '') // 去除多余的负号
      .replace(/\-/, '#$#') // 将负号暂存为其他字符
      .replace(/\-/g, '') // 替换多余的负号, 防止输入时的闪烁
      .replace('#$#', '-') // 替换完成之后将暂存负号替换回来
      .replace(/\.{2,}/g, '.') // 去除多余小数点
      .replace('.', '$#$') // 将小数点暂存为其他字符
      .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
      .replace('$#$', '.') // 将暂存的小数点替换回来
      .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只保留三位小数
  }
  return data
    .replace(/^[^\d]/) // 第一个字符只能输入负号或数字
    .replace(/[^\d.|\-]/g, '') // 去除数字和小数点以外的数字
    .replace(/\-{1,}/g, '') // 去除多余的负号
    .replace(/\-/, '#$#') // 将负号暂存为其他字符
    .replace(/\-/g, '') // 替换多余的负号, 防止输入时的闪烁
    .replace('#$#', '-') // 替换完成之后将暂存负号替换回来
    .replace(/\.{2,}/g, '.') // 去除多余小数点
    .replace('.', '$#$') // 将小数点暂存为其他字符
    .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
    .replace('$#$', '.') // 将暂存的小数点替换回来
    .replace(/^(\-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3') // 只保留三位小数
}

export function regReplaceBaseMaterialDealDecimal(data: string): string {
  return (
    data
      // .replace(/^[^\d]/) // 第一个字符只能输入负号或数字
      .replace(/[^\d.]/g, '') // 去除数字和小数点以外的数字
      .replace(/\.{2,}/g, '.') // 去除多余小数点
      .replace('.', '$#$') // 将小数点暂存为其他字符
      .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
      .replace('$#$', '.') // 将暂存的小数点替换回来
      .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
  ) // 只保留两位小数
  // if (decimal) {
  //   return data
  //     .replace(/^[^\d]/) // 第一个字符只能输入负号或数字
  //     .replace(/[^\d.]/g, '') // 去除数字和小数点以外的数字
  //     .replace(/\.{2,}/g, '.') // 去除多余小数点
  //     .replace('.', '$#$') // 将小数点暂存为其他字符
  //     .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
  //     .replace('$#$', '.') // 将暂存的小数点替换回来
  //     .replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3') // 只保留两位小数
  // } else {
  //   return data
  //     .replace(/^[^\d]/) // 第一个字符只能输入负号或数字
  //     .replace(/[^\d.]/g, '') // 去除数字和小数点以外的数字
  //     .replace(/\.{2,}/g, '.') // 去除多余小数点
  //     .replace('.', '$#$') // 将小数点暂存为其他字符
  //     .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
  //     .replace('$#$', '.') // 将暂存的小数点替换回来
  //     .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只保留两位小数
  // }
}

export function regReplaceMaterialListCheckInpput(data: any) {
  return data
    .replace(/[^\d.]/g, '') // 去除数字和小数点以外的东西
    .replace(/\.{2,}/g, '.') // 去除多余小数点
    .replace('.', '$#$') // 将小数点暂存为其他字符
    .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
    .replace('$#$', '.') // 将暂存的小数点替换回来
}

export function regReplaceNewMaterialListCheckInpput(data: any) {
  return data
    .replace(/[^\d.]/g, '') // 去除数字和小数点以外的数字
    .replace(/\.{2,}/g, '.') // 去除多余小数点
    .replace('.', '$#$') // 将小数点暂存为其他字符
    .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
    .replace('$#$', '.') // 将暂存的小数点替换回来
}

export function regReplaceSurPlusTempHandleInputVal(
  data: any,
  Reg: any,
  repStr: any
) {
  return data
    .replace(/\.+?/, '(')
    .replace(/\./g, '')
    .replace(/\(/, '.')
    .replace(Reg, repStr)
    .replace(/[^\d\.\-]/, '')
}

export function regReplaceGlassEquipHandleInputVal(
  data: any,
  Reg: unknown,
  repStr: unknown
) {
  return data
    .replace(/\.+?/, '(')
    .replace(/\./g, '')
    .replace(/\(/, '.')
    .replace(Reg, repStr)
    .replace(/[^\d\.\-]/, '')
}

export function regReplaceAddSurPlusTempHandleInputVal(
  data: any,
  Reg: unknown,
  repStr: unknown
) {
  return data
    .replace(/\.+?/, '(')
    .replace(/\./g, '')
    .replace(/\(/, '.')
    .replace(Reg, repStr)
    .replace(/[^\d\.\-]/, '')
}
export function regReplaceInputNumberInputChange(
  data: any,
  Reg: unknown,
  repStr: unknown
) {
  return data
    .replace(/\.+?/, '(')
    .replace(/\./g, '')
    .replace(/\(/, '.')
    .replace(/[^\d.-]/g, '')
    .replace(Reg, repStr)
    .replace(/^-+?/, '^')
    .replace(/-/g, '')
    .replace(/\^/, '-')
}
